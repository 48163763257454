const menu = document.querySelector(".menu");
const menuItems = document.querySelectorAll(".menu__item");
const burger = document.querySelector(".hamburger");
const container = document.querySelector(".container");
const video = document.querySelector(".showcase__video");
const email1 = document.querySelector(".contact__email");

const toogleBurger = (e) => {
  menu.classList.toggle("is-active");
  burger.classList.toggle("is-active");
  container.classList.toggle("is-active");
};

const addVideo = (e) => {
  const video = document.createElement("div");
  let src = "";

  if (window.innerWidth >= 740) src = "dist/img/video/pit_1280.mp4";
  else src = "dist/img/video/pit_640.mp4";

  video.className = "showcase__video-container";
  video.innerHTML = `
    <video class="showcase__video" src=${src} muted loop autoplay type="video/mp4"></video>
    <div class="showcase__overlay"></div>
  `;
  document.getElementById("home").appendChild(video);
};

const changeEmailName = (e) => {
  const pref = "uzapy".concat("taniak").slice(1, -1);
  const suf = "brvip.pl";
  const a = document.createElement("a");

  a.href = `mailto:${pref}@${suf}`;
  a.innerText = `${pref}@${suf}`;

  email1.appendChild(a);
};

changeEmailName();
addVideo();

burger.addEventListener("click", toogleBurger);
menuItems.forEach((e) => {
  e.addEventListener("click", toogleBurger);
});
